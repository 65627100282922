.contact-content {
  display:flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10vh 10vw;
  text-align: center;

  .contact-image {
    font-family: $font-stack;
    font-weight: 600;
    color: $clr_brown;
    font-size: 1.4em;

    a {
      color: $clr_brown;
      cursor:pointer;
    }
  }
}

/**** FORM ****/
.contact-form-container{
  display:flex;
  flex-direction: column;

  width:100%;
  padding-left:10vw;
}
#ContactForm {
  width:100%;
  text-align: left;

  h6 {
    color:$clr_brown;
    font-family: $font-stack;
    font-weight:400!important;
    font-size: .8em;
  }
}
.contact-row {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;

  margin-bottom:20px;
}
.contact-field {
  width: 100%;
  //margin-bottom:20px;
  justify-content: space-between;
  align-items: stretch;
}
#FirstName, #LastName, #Email, #Phone, input {
  background-color: $clr_muted_sand;
  height:36px;
  border:none;
  font-style:normal;
  font-weight:300;

  width:90%;
  padding-left:10px;
}
#Message, textarea{
  background-color: $clr_muted_sand;
  height:140px;
  border:none;
  font-style:normal;
  font-weight:300;

  width: 95%;
  padding-left:10px;
  resize:none;
  padding-top:5px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $clr_brown;
  opacity: 1; /* Firefox */
  font-size:1em;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $clr_brown;
  font-size:1em;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: $clr_brown;
  font-size:1em;
}
.g-recaptcha{
  display:inline-block;
}
.contact-submit {
  width:95%;
}
#SubmitButton {
  font-family: $font-stack;
  background-color: $clr_seagreen;
  font-size:calc(12px + 1vmin);
  color:$clr_white;
  font-weight: 300;
  padding: 10px 48px 10px 48px;
  border-radius: 30px;
  border:none;
  letter-spacing: 2px;
  text-transform: uppercase;

  float:right;

  opacity: 1;

  &:hover{
    cursor: pointer;
  }
}
#SubmitButton:hover:enabled {
  opacity: .8;
}

.contact-form-error{
  display:none;
}
#formError > div{
  color: $clr_brown;
  //display:none;
  padding:25px;
  height:75px;
}
#formError > div.alert-success{
  border: 1px solid #000;
  color: #000;
}

//Contact Form error handling
.contact-form-retry {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(33, 33, 33, .8);
  z-index: 1008;
  display: none;

  vertical-align: middle;
  text-align: center;

  padding-top: 20%;

  .retry-info {
    background: white;
    width: 40%;
    height: 40%;

    display: inline-block;

    padding-top: 5%;
  }
}
.contact-form-progress {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  background: rgba(33,33,33,.8);
  z-index: 1008;
  display: none;

  vertical-align: middle;
  text-align: center;

  padding-top:20%;

  .contact-form-info {
    background: white;
    width:40%;
    height:40%;

    display: inline-block;

    padding-top:5%;

    img {
    }
  }
}
.contact-form-success {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  background: rgba(33,33,33,.8);
  z-index: 1008;
  display: none;

  vertical-align: middle;
  text-align: center;

  padding-top:20%;

  .success-info {
    background: white;
    width:40%;
    height:40%;
    color:black!important;

    display: inline-block;

    padding-top:5%;

    img {
    }
  }
}
.unicodeX {
  font-size:40px;
}
.unicodeCheck{
  color:green;
  font-size:45px;
}
.retryLink {
  color:black;
  font-size:18px;
  cursor:pointer;
}
.retryLink:hover {
  color:gray;
  text-decoration: underline;
}
@media only screen and (max-device-width: 767px) {
  .contact-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contact-image {
      font-size: 1.2em;

      margin-bottom:20px;
    }
    .contact-image img {
      max-width:100%;
    }
  }
  .contact-form-container {
    padding-left:0px;
  }
  .contact-row {
    flex-direction: column;
    margin-bottom:0px;
  }
  .contact-field {
    margin-bottom:20px;
  }
  #FirstName, #LastName, #Email, #Phone, input {
    width:100%;
  }
  #Message, textarea{
    width: 100%;
    height:200px;
  }
}
