#footer {
  //margin:0;
  //padding:0;

  background-color: $clr_muted_sand;
  height:24vh;

  color: $clr_brown;

  overflow:hidden;

  .footer-content{
    display:flex;
    flex-direction: row;
    //justify-content: space-between;
    height:24vh;

    padding:1em;
  }
  .footer-vet-info{
    text-align: center;
  }
  .footer-left {
    align-self:flex-end;
    width:20%;
  }
  .footer-left img {
    width:100%;
  }
  .footer-center {
    display:flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;

    width:60%;
  }
  .footer-right {
    width:20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top:2vh;
  }

  .footer-social-media, .footer-cat {
    //justify-content: center;
    //align-items: center;
    //align-content: center;
    //justify-items: center;
    //padding-left:5vw;
  }
  .footer-social-media{
    display: flex;
    justify-content: space-between;
  }
  .footer-social-media img {
    width:70%;
    padding-right:10px;

  }
  .footer-cat {
    //align-self: flex-end;
  }
  .footer-cat img {
    width:70%;
  }
  .footer-nav {
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 10px;

    a {
      font-family: $font-stack;
      font-weight:400;

      color:$clr_brown;
      font-size: calc(14px + 1vmin);

      padding: 0 20px;
      cursor: pointer;
    }
  }
  .footer-copy {
    font-family: $font-stack;
    font-weight: 300;
    font-size: calc(10px + .8vmin);

    a {
      font-family: $font-stack;
      color:$clr_brown;
      font-weight:300;
      font-size: calc(10px + .8vmin);

      cursor:pointer;
    }
  }
 .vet-practice{
   font-family: $font-stack-whitman;
   font-weight: 600;
   font-size: calc(18px + 2vmin);
 }
  .vet-name {
    font-family: $font-stack;
    font-weight: 400;
    font-size: calc(16px + 1vmin);
  }
  .vet-phone {
    font-family: $font-stack;
    font-weight: 300;
    font-size: calc(16px + 1vmin);
    line-height: 2;

    a {
      color:$clr_brown;
      cursor:pointer;
    }
  }
}

@media only screen and (max-device-width: 767px) {
  #footer {
    height:20vh;
    .footer-content{
      height:20vh;

      padding:10px;
      padding-top:5px;
    }
    .footer-center {
      display:flex;
      flex-wrap: nowrap;
      //width:80%;
    }
    .footer-vet-info{
      flex-wrap: nowrap;
    }
    .footer-nav {
      display: none;
    }
    .footer-cat {
      //display:none;
    }
    .footer-cat img {
      width:30%;
      float:right;
      margin-right:20px;
    }
    .footer-right {
      align-self:flex-end;
      width:20%;
    }
    .footer-social-media{
      display: none;
      justify-content: space-between;
    }
    .footer-social-media img {
      width:90%;
      padding-right:0;
      padding-left:2px;
    }
    .footer-copy {
      font-size: 12px;
      text-align: center;

      a {
        font-size: 12px;
      }
    }
    .vet-practice{
      font-weight: 600;
      font-size: calc(14px + 2vmin);
      white-space: nowrap;
    }
    .vet-name {
      font-weight: 300;
      font-size: calc(13px + 1vmin);
      white-space: nowrap;
    }
    .vet-phone {
      font-size: calc(12px + 1vmin);
      line-height:2;
    }
  }
}
