.home-top-image {
  //margin-bottom: $header_height/ 2;

  position:relative;

  display:block;
  width:100%;
  height:calc(100vh - 160px);

  background-image: url("../images/home/WHV_R2_Banner_collageV3_bluefilter_opacity60.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  img {
    width:100%;

    height:auto;
    max-width:100vw;

  }

  .home-top-tagline {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-family: $font-stack-whitman;
    font-weight: 700;
    font-size: calc(18px + 3vmin);
    line-height: 1.2;

    color: $clr_white;
    text-shadow: 7px 7px 5px #000000;
    text-align: center;
  }
}
//combine
.homeContactButton, .homeContactButtonMobile {
  font-family: $font-stack;
  background-color: $clr_seagreen;
  font-size:calc(12px + 1vmin);
  color:$clr_white;
  font-weight: 300;
  padding: 14px 40px 14px 40px;
  border-radius: 30px;
  border:none;
  letter-spacing: 2px;
  text-transform: uppercase;

  &:hover{
    cursor: pointer;
  }
}
.homeContactButtonMobile{
  display:none;
  width:60vw;
  height:10vh;

  margin: 0 auto;
}
.emergencyCallMobile{
  display:none;

  font-family: $font-stack;
  background-color: $clr_navy;
  font-size:calc(12px + 1vmin);
  color:$clr_white;
  font-weight:300;
  padding: 14px 40px 14px 40px;
  border-radius: 30px;
  border:none;
  letter-spacing: 2px;
  text-transform: uppercase;

  width:60vw;
  height:10vh;

  //margin-top:40px;
  //margin-bottom: 20px;

  &:hover{
    cursor: pointer;
  }
}
.home-top-image-mobile {
  display:none;
}
.top-emergency-banner {
  display:none;
}

@media only screen and (max-device-width: 991px) {
  .home-content{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .homeContactButton {
    display:none;
  }
  .homeContactButtonMobile, .emergencyCallMobile{
    display:block;
  }
  .homeContactButtonMobile {
    margin-top:20px;
    margin-bottom: 40px;
  }
  .home-top-image {
    display:none;

    img {
      //overflow-x: hidden;
      //height:40vh;
      //width:auto;
      //max-width:unset;
      //width:100%;
      //max-width:100vw;
    }

    .home-top-tagline {
      position: absolute;
      top: 60%;
      left: 50%;

      font-weight: 600;
      font-size: calc(18px + 1vmin);
      line-height: 1.4;

      width:100%;

      color: $clr_white;
      text-shadow: 7px 7px 5px #000000;
      text-align: center;
    }
  }
  .home-top-image-mobile{
    display:block;
    width:100%;
    height:70vh;

    background-image: url("../images/home/WHV_R2_Banner_collageV3_bluefilter_opacity60.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .home-top-tagline-mobile{
      margin-top:15vh;

      font-weight: 400;
      font-size: calc(18px + 1vmin);
      line-height: 1.4;

      width:100%;

      color: $clr_white;
      text-shadow: 4px 4px 3px #000000;
      text-align: center;
    }

    a {
      text-decoration: none;
    }
  }
}

@media only screen and (max-device-width: 767px) {
  .top-emergency-banner {
    background-color: $clr_navy;
    color: $clr_white;

    width:100%;
    height:7vh;

    display:flex;
    justify-content: space-around;
    align-items: center;

    margin-top:0;
    padding-left:5vw;
    padding-right:5vw;

    text-align: center;

    .emergency-bar-text {

    }

    .emergency-bar-link  {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
