#header{
  background-color: rgba(255, 255, 255, 1);
  height:$header_height;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
  z-index:999;

  border-bottom: 1px solid $clr_muted_sand;
}
.main-navbar{
  height:100%;
  max-width:100%;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  margin-right:5%;
  margin-left:5%;

  .navbar-desktop {
    display: flex;
    flex-direction: column;
    align-content: space-between;

    min-width:50%;
  }

  .navbar-button {
    align-self: flex-end;
    border:none;

    button:active,
    button:focus {
      border: 0px solid white;
      outline: 0;
    }

    .emergencyCall{
      font-family: $font-stack;
      background-color: $clr_navy;
      font-size: 11px;
      color:$clr_white;
      font-weight:500;
      padding: 12px 24px 12px 24px;
      border-radius: 30px;
      border:none;

      &:hover{
        cursor: pointer;
      }
    }
  }
  .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-content: space-between;
    align-items: center;
    justify-items: center;

    padding-top:10px;
  }
}

.navbar-brand img {
  max-height:calc(50px + 3vmin);
  max-width:100%;
}

#header a.selected {
  //border-bottom: 3px solid $clr_red;
}

.nav-item {
  padding-left:calc(20px + 2vw);

  a {
    font-family: $font-stack;
    font-weight:300;

    color:$clr_gray;
    font-size: calc(10px + 1vmin);

    border-bottom: 3px solid transparent;

    &:hover{
      color: $clr_seagreen;
    }
  }
  a:after {
       display:block;
       content: '';
       padding-top:10px;
       border-bottom: solid 3px #019fb6;
       transform: scaleX(0);
       transition: transform 250ms ease-in-out;
     }
  a:hover:after {
    transform: scaleX(.5);
    color: $clr_seagreen;
  }
}

.mobile-buttons {
  display:none;
}

/*Mobile Menu*/
/*****************************************************************************************************/
.mobile-nav-menu{
  display:none;
  position:fixed;
  top: 160px;
  //bottom:0;
  left: 0;
  right: 0;
  z-index: 999;
  flex-direction:column;
  background-color: rgba(255, 255, 255, .97);
  height:68vh;

  padding-bottom: 20px;
  border-bottom: 1px solid $clr_muted_sand;

  .mobile-nav-item{
    text-align:center;
    width:100%;

    color:$clr_brown;
    font-weight: 600;
    font-family: $font-stack;
    text-transform: uppercase;

    padding-top:15px;
    padding-bottom:15px;

    cursor:pointer;

    &:first-child{
      margin-top:20px;
    }
  }
  .mobile-nav-item:hover {
    color: $clr_muted_sand;
  }
  .emergency-nav-item{
    text-align:center;
    width:100%;

    color:$clr_navy;
    font-weight: 600;
    font-family: $font-stack;
    text-transform: uppercase;

    padding-top:15px;
    padding-bottom:15px;

    cursor:pointer;

    &:first-child{
      margin-top:20px;
    }
  }
  .emergency-nav-item:hover {
    color: $clr_seagreen;
  }
}
.mobile-close{
  display:none;
}
.mobile-button, .mobile-close{
  height:45px;
  width:45px;
  padding-top:0;
  z-index:1000;

  cursor:pointer;

  img{
    width:100%;
  }
}
@media only screen and (max-device-width: 991px) {
  .main-navbar {
    .navbar-desktop {
      display: none;
    }
  }
  .mobile-buttons {
    display:block;
  }

  .mobile-nav-item a:hover {
    text-decoration: none;
  }

  .mobile-nav-item a img {
    width:30%;
    padding:20px;
  }
}
