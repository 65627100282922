.services-content {
  padding: 10vh 10vw;
  text-align: center;

  p {
    font-size: 1em;
    font-weight:400;
    font-family: $font-stack;
  }

  .service-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .service-item {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;

    height:25vh;
    //border: 1px solid black;
    padding:1em;
    margin: 1em;
    flex:1;
    //height: auto;
  }
  .service-item-text{
    font-family: $font-stack-whitman;
    font-weight: 600;
    color:  $clr_brown;
    font-size: calc(16px + 1vmin);
  }
}
.service-item-overlay{
  display: none;

  h3{
    font-family: $font-stack-whitman;
    font-weight: 700;
    color:  $clr_navy;
    font-size: calc(16px + 1vmin);
    //border-bottom: 5px solid $clr_navy;
  }
  h3:after {
    display:block;
    content: '';
    padding-top:10px;
    border-bottom: solid 5px #37396e;
    transform: scaleX(.5);
  }
  p {
    font-family: $font-stack-whitman;
    font-weight: 400;
    color:  $clr_brown;
    font-size: calc(16px + .5vmin);

    padding-top:5vh;
  }
  span {
    cursor: pointer;
  }
}
.service-intro{
  p {
    font-family: $font-stack;
    font-weight: 300;
    color: $clr_navy;
    font-size: calc(12px + 1vmin);

    margin-bottom:10vh;
  }
}
.service-text{
  margin-top:4vh;
  margin-bottom:10vh;
  background-color: $clr_muted_sand;

  padding: 10vh 5vw;
  text-align: center;

  h4 {
    font-family: $font-stack;
    font-style: normal;
    font-weight: 500;
    color: $clr_navy;
    font-size: calc(16px + 1vmin);
  }

  p {
    font-family: $font-stack;
    font-style: normal;
    font-weight: 300;
    color: $clr_navy;
    font-size: calc(12px + 1vmin);

    margin-top:4vh;
  }
}
.service-text-italic{
  font-style: italic!important;
  margin-top:10vh!important;
}
.serviceContactButton {
  font-family: $font-stack;
  background-color: $clr_seagreen;
  font-size:calc(12px + 1vmin);
  color:$clr_white;
  font-weight: 300;
  padding: 14px 40px 14px 40px;
  border-radius: 30px;
  border:none;
  letter-spacing: 2px;
  text-transform: uppercase;

  &:hover{
    cursor: pointer;
  }
}
.services-map-banner {
  background-color: $clr_navy;

  padding: 5vh 10vw;

  display:flex;
  flex-direction: row;
  justify-content: center;

  .services-map-image {
    flex:1;
  }
  .services-map-image img {
    width:100%;
  }
  .services-map-info {
    flex:1.5;
    margin-left:10vw;

    font-family: $font-stack;
    font-weight: 300;
    color: $clr_white;
    font-size: calc(16px + .6vmin);

    align-self:center;

    padding-top: 5vh;
    padding-bottom:5vh;

    h4 {
      font-family: $font-stack-whitman;
      font-weight: 700;
      font-size: calc(16px + 3vmin);

      padding-bottom:2vh;
    }
    ul {
      padding-left:5vw;
      margin: 3vh 0;
    }
  }
}

//Service popups
/*** Overlay ***/
.overlayFilterService {
  position: fixed;
  display: none;
  top: $header_height;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 57, 110, 1);
  z-index: 999;
  flex-direction: column;

  .overlayFilterContainer {
    width: 100vw;
    height: calc(100vh - 160px);
    overflow:scroll;

    position:relative;

    .close-overlay {
      font-size:1.6em;
      color: $clr_white;

      display:flex;
      flex-direction: column;
      justify-content: flex-start;

      position:absolute;

      top:0;
      left:0;

      padding-top:12px;
      padding-left:16px;

      cursor:pointer;
    }

    .service-title {
      background-color: rgba(55, 57, 110, 1);
      height: 20%;
      border-bottom: 1px solid white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: $clr_white;
      font-size: calc(16px + 2vmin);
    }

    .service-paragraph {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      color: $clr_white;
      font-weight: 300;
      font-size: calc(12px + 1vmin);
      padding: 2em 20vw;

      p a {
        color: $clr_brown;
        font-weight: 400;

        &:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }
      li {
        margin-bottom:1em;
      }
    }
  }
}

@media only screen and (max-device-width: 1440px) {
  .services-content {
    .service-item {
      height: 30vh;
    }
    .service-item-text {
      font-size: calc(16px + .2vmin);
    }
  }
  .service-item-overlay{
    h3{
      font-size: calc(16px + .8vmin);
    }
    p {
      font-size: calc(16px + .2vmin);
    }
  }
}
@media only screen and (max-device-width: 767px) {
  .services-content {
    padding: 10vh 10vw;
    text-align: center;

    .service-items {
      flex-direction: column;
      justify-content: space-between;
    }
    .service-item {
      min-height:30vh;
    }
  }
  .services-map-banner {
    padding: 5vh 10vw;
    flex-direction: column;
  }
}
