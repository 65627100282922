.appointment-content {
  padding: 10vh 10vw;
  text-align: left;

  background-image: url("../images/appointments/Paw Watermark.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;

  h3{
    font-family: $font-stack-whitman;
    font-weight: 700;
    color: $clr_brown;
    font-size: calc(20px + 2vmin);
  }
  p {
    font-family: $font-stack;
    font-weight: 300;
    font-style: normal;
    color: $clr_brown;
    font-size: calc(14px + 1vmin);

    padding-top:1em;
    padding-bottom:2em;
  }
  .appointments-schedule{
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 4px solid $clr_muted_sand;

    .app-day {
      font-family: $font-stack;
      font-weight: 300;
      font-style: normal;
      color: $clr_brown;
      font-size: calc(16px + 1vmin);
      text-align: center;
      padding-bottom:1.4em;

      .app-hours {
        font-family: $font-stack;
        font-weight: 600;
        font-style: normal;
        color: $clr_brown;
        font-size: calc(14px + 1vmin);

        padding-top:1em;
      }
    }
  }
  .appointment-info {
    padding-top:3em;

    font-family: $font-stack;
    font-weight: 300;
    font-style: normal;
    color: $clr_brown;

    font-size: calc(16px + 1vmin);
  }
}

@media only screen and (max-device-width: 767px) {
  .appointment-content {
    text-align: center;

    .appointments-schedule {
      flex-direction: column;
      justify-content: space-between;

      .app-day {
        .app-hours {

        }
      }
    }
  }
}

