.blog-content {
  padding-top: 10vh;
  padding-bottom:10vh;
  padding-left:5vw;
  padding-right:5vw;
  text-align: center;

  .carousel-flex-item {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    //justify-items: stretch;
    //align-items: stretch;
    //align-content: space-between;

    .h3-title, .readmore-title {
      flex:1;
    }
  }

  h3{
    font-family: $font-stack-whitman;
    font-weight: 700;
    color:  $clr_brown;
    font-size: calc(18px + 1vmin);

    padding:20px;
    height:14vh;
  }
  .readMore {
    font-family: $font-stack;
    font-weight: 700;
    color:  $clr_navy;
    font-size: calc(14px + 1vmin);

    cursor: pointer;
  }
}
//
//.blog-wrapper {
//  display:flex;
//  flex-direction:row;
//  justify-content: space-around;
//
//  width:90vw;
//
//  height:auto;
//
//  position:relative;
//}
//.slider-item {
//  width:30%;
//
//  margin-left:2vw;
//  margin-right:2vw;
//
//  .item-image{
//    width:100%;
//
//    img {
//      width:100%;
//    }
//  }

  //.item-text{
  //  display:flex;
  //  flex-direction: column;
  //  justify-content: space-between;
  //
  //  .item-title{
  //    font-size:1.4em;
  //    color:$clr_brown;
  //  }
  //  .item-read {
  //
  //  }
  //}
//}

.button-wrapper {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;

  width:105%;
  height:100%;

  position:absolute;
}


//.carousel-control-next {
//  color:black!important;
//  z-index:9999;
//
//}
//.carousel-control-prev {
//  color: black !important;
//  z-index: 9999;
//}
.carousel-control-prev {
  margin-left: -8vw;
  margin-top:-130px;

  &:hover{
    cursor: pointer;
  }
}

.carousel-control-next {
  margin-right: -8vw;
  margin-top:-130px;

  &:hover{
    cursor: pointer;
  }
}
.overlayFilterArticle {
  .overlayFilterContainer {
    .article-paragraph {
      font-size: calc(12px + 1vmin);
      padding: 2em 10vw;
    }
  }
}

@media (min-width: 768px) {

  /* show 3 items */
  .carousel-inner .active,
  .carousel-inner .active + .carousel-item,
  .carousel-inner .active + .carousel-item + .carousel-item,
  .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item  {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item{
    transition: none;
  }
  //.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item

  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    position: relative;
    transform: translate(0, 0);
    //transform: translate3d(0, 0, 0);
  }

  .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    //+ .carousel-item
    position: absolute;
    top: 0;
    right: -33%;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* left or forward direction */
  .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  .carousel-item-next.carousel-item-left + .carousel-item,
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item{
    //.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item
    position: relative;
    transform: translate(-100%, 0);
    //transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* farthest right hidden item must be abso position for animations */
  .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* right or prev direction */
  .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  .carousel-item-prev.carousel-item-right + .carousel-item,
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item{
    //.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item
    position: relative;
    transform: translate(100%, 0);
    //transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}

//Article popups
/*** Overlay ***/
.overlayFilterArticle {
  position: fixed;
  display: none;
  top: $header_height;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(216, 207, 197, .95);
  z-index: 999;
  flex-direction: column;

  .overlayFilterContainer {
    width: 100vw;
    height: calc(100vh - 160px);
    overflow:scroll;

    position:relative;

    .close-overlay {
      font-size:1.6em;
      color: $clr_white;

      display:flex;
      flex-direction: column;
      justify-content: flex-start;

      position:absolute;

      top:0;
      left:0;

      padding-top:12px;
      padding-left:16px;

      cursor:pointer;
    }

    .article-title {
      background-color: rgba(216, 207, 197, .97);
      height: 20%;
      border-bottom: 1px solid white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: $clr_brown;
      font-size: calc(16px + 2vmin);
    }

    .article-paragraph {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      color: $clr_brown;
      font-weight: 300;
      font-size: calc(12px + 1vmin);
      padding: 2em 20vw;

      p a {
        color: $clr_brown;
        font-weight: 400;

        &:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }
      li {
        margin-bottom:1em;
      }
    }
  }
}
