.about-content {
  padding: 10vh 10vw;
  text-align: center;

  .about-name h2 {
    font-family: $font-stack-whitman;
    font-weight: 700;
    color: $clr_brown;
    font-size: calc(28px + 4vmin);
  }
  .about-photo{
    padding:2em;

    img {
      width: 12%;
    }

  }
  .about-text{
    font-family: $font-stack;
    font-weight: 300;
    color: $clr_brown;
    font-size: calc(12px + 1vmin);

    .about-text-quote {
      font-weight: 400;
      font-style: italic;
      font-size: calc(14px + 1.4vmin);
    }
  }
  .about-images img{
    width:14%;
  }
}
.carousel__photo p {
  font-family: $font-stack;
  font-weight: 500;
  color: $clr_white;
  font-size:1.4em;
  //text-shadow: 7px 7px 5px #000000;
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
}
.carousel-wrapper * {
  box-sizing: border-box;
}
.carousel {
  transform-style: preserve-3d;
}
.carousel__photo {
  //opacity: .4;
  position: absolute;
  top:0;
  width: 100%;
  margin: auto;
  //padding: 1rem 4rem;
  z-index: 100;

  //transition: opacity 500ms linear;
  //transition: transform .5s, opacity .5s, z-index .5s;
}
.carousel__photo img {
  width:100%;
}
.carousel__photo p {
  position:absolute;
  top: 60%;
  left: 10vw;
  right: 10vw;
  transform: translateY(-50%);
  text-align: center;
}
.carousel__photo.initial,
.carousel__photo.active {
  opacity: 1;
  position: relative;
  z-index: 900;
}

.carousel__photo.prev,
.carousel__photo.next {
  z-index: 800;
}
.carousel__photo.prev {
  transform: translateX(-100%); /* Move 'prev' item to the left */
}
.carousel__photo.next {
  transform: translateX(100%); /* Move 'next' item to the right */
}
.carousel__button--prev,
.carousel__button--next {
  position: absolute;
  top:50%;
  width: 3rem;
  height: 3rem;
  //background-color: #FFF;
  transform: translateY(-50%);
  //border-radius: 50%;
  cursor: pointer;
  z-index: 1001; /* Sit on top of everything */
  //border: 1px solid black;
}
.carousel__button--prev {
  left:5vw;
}
.carousel__button--next {
  right:5vw;
}
.carousel__button--prev::after,
.carousel__button--next::after {
  content: " ";
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 54%;
  border-right: 8px solid white;
  border-bottom: 8px solid white;
  transform: translate(-50%, -50%) rotate(135deg);
}
.carousel__button--next::after {
  left: 47%;
  transform: translate(-50%, -50%) rotate(-45deg);
}




@media only screen and (max-device-width: 767px) {
  .about-content {
    padding: 10vh 5vw;
    .about-name h2 {
      font-size: calc(26px + 2vmin);
    }
    .about-photo{
      padding:3em;
      img {
        width:50%;
      }
    }
    .about-text{
      font-size: calc(13px + 1vmin);

      .about-text-quote {
        font-weight: 400;
        font-style: italic;
        font-size: calc(15px + 1vmin);
      }
    }
    .about-images img {
      width: 60%;
    }
  }

  .carousel-wrapper {
    display:none;
  }
}
