*, html {
  margin:0;
  padding:0;

  scroll-behavior: smooth;
}
html {
  max-width:100vw;
  overflow-x:hidden;
}
body {
  font-family: $font-stack;
  color: $clr_black;
  font-weight: 400;
  font-style:normal;
  font-size: 100%;
}
.body-content {
  padding-top:$header_height;
}
.top-banner {
  background-color: $clr_muted_sand;

  width:100%;
  height:150px;

  display:flex;
  justify-content: center;
  align-items: center;

  //margin-top:7vh;
  padding-left:5vw;
  padding-right:5vw;

  text-align: center;
}
/*** FONTS ******/
h1{
  font-family: $font-stack-whitman;
  font-weight: 700;
  color: $clr_navy;
  font-size: calc(32px + 4vmin);

  margin-bottom: 0px!important;
}

/*** Overlay ***/
.overlayFilter{
  position: fixed;
  display: none;
  top: $header_height;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 57, 110, 1);
  z-index: 999;
  flex-direction: column;

  .overlayFilterContainer {
    width:100vw;
    height:calc(100vh - 160px);

    position:relative;

    .close-overlay {
      font-size:1.6em;
      color: $clr_white;

      display:flex;
      flex-direction: column;
      justify-content: flex-start;

      position:absolute;

      top:0;
      left:0;

      padding-top:12px;
      padding-left:16px;

      cursor:pointer;
    }

    .overlay-title {
      background-color: rgba(55, 57, 110, 1);
      height:20%;
      border-bottom: 1px solid white;

      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding:1.2em;

      color: $clr_white;
      font-size:calc(16px + 2vmin);
    }
    .overlay-paragraph {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: $clr_white;
      font-weight: 300;
      font-size:calc(12px + 2vmin);
      text-align: center;
      padding:1.4em;
      padding-left:.7em;

      .emergencyLocation {
        padding:20px;
        font-weight: 300;
      }
      //combine
      .overlayContactButton {
        background-color: $clr_seagreen;
        font-size:calc(12px + 1vmin);
        color:$clr_white;
        padding: 14px 40px 14px 40px;
        border-radius: 30px;
        border:none;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 2px;

        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-device-width: 991px) {
  .overlayFilter {
    top: 0;

    .overlayFilterContainer{
      .overlay-paragraph {
        font-size: calc(12px + 1.4vmin);
        text-align: center;
        padding: 1.2em;

        .emergencyLocation {
          padding: 20px;
          font-weight: 300;
          font-size: calc(12px + 2vmin);
        }
      }
    }
  }
}
